<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="overflow-auto custom-scroll" style="height:calc(100vh - 127px);">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                { titulo: 'Clientes', ruta: 'admin.datos-cuentas.clientes', can:'tab1_clientes_actualizaciones'},
                { titulo: 'Vendedores', ruta: 'admin.datos-cuentas.leecheros', can:'tab1_leecheros_actualizaciones'},
                { titulo: 'Lanzamientos de actualización', ruta: 'admin.datos-cuentas.lanzamientos.clientes', can:'tab1_actualizaciones'}
            ]
        }
    },
    mounted(){
        this.rutas[0].titulo = this.$config.cliente
        this.rutas[1].titulo = this.$config.vendedor
    }
}
</script>
